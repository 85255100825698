.Contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .body {
        margin: 2rem;

        h1 {
            margin: 2rem 0;
        }
    }
}