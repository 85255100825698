@import url(../../../assets/styles/variables.scss);

.MobileMenu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;

    background: var(--liliac-to-blue);
    z-index: 100;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    right: -100%;
    transition: right 0.3s;

    // Force GPU usage
    transform: translateZ(1);

    &.open {
        right: 0;
    }

    .menu-items {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            margin: 1.5rem 0;
        }
    }

    .toggle {
        position: fixed;
        top: .5rem;
        right: 1rem;
    }

    .Socials {
        margin-top: 5rem;
    }
}

.toggle {
    cursor: pointer;
}