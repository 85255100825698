@import url(variables.scss);
@import url(typography.scss);

html, body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    
    background-color: var(--bg-color);
    font-family: Recoleta, Arial, Helvetica, sans-serif;
    font-display: swap;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
}

.vert-spacing {
    margin: 1.25rem 0;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// Large screens
@media screen and (min-width: 650px) {
    .center {
        flex-direction: row;
    }

    .vert-spacing {
        margin: 3rem 0;
    }
}