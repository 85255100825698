@font-face {
    font-family: 'Recoleta';
    src: url('../fonts/Recoleta-Regular.eot');
    src: local('Recoleta Regular'), local('Recoleta-Regular'),
        url('../fonts/Recoleta-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Recoleta-Regular.woff2') format('woff2'),
        url('../fonts/Recoleta-Regular.woff') format('woff'),
        url('../fonts/Recoleta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recoleta';
    src: url('../fonts/Recoleta-Bold.eot');
    src: local('Recoleta Bold'), local('Recoleta-Bold'),
        url('../fonts/Recoleta-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Recoleta-Bold.woff2') format('woff2'),
        url('../fonts/Recoleta-Bold.woff') format('woff'),
        url('../fonts/Recoleta-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
