@import url(../../assets/styles/variables.scss);

.Card {
    background-color: var(--liliac);
    padding: .5rem;
    margin: .5rem;
    border-radius: .5rem;

    p {
        font-size: .5rem;
        margin: 0;
    }

    h1 {
        margin: .25rem 0;
        font-size: 1rem;
    }

    h2 {
        margin: 0;
        font-size: .75rem;
    }

    .link {
        display: block;
        margin-top: .5rem;
        text-decoration: underline;
        font-size: .75rem;
    }
}

// Large screens
@media screen and (min-width: 650px) {
    .Card {
        padding: 1rem;
        margin: 1rem;

        p {
            font-size: .75rem;
        }
    
        h1 {
            font-size: 1.75rem;
        }
    
        h2 {
            font-size: initial;
        }

        .link {
            font-size: initial;
        }
    } 
}